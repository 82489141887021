import React, { useState } from 'react';
import MasterForm from './master-form/master-form';
import {authFormSchema} from './form-schemas/auth-form-schema';
import {regFormSchema} from './form-schemas/reg-form-schema';
import {restoreFormSchema} from './form-schemas/restore-form-schema';
import './authorization.css';

const Authorization = ({getAuthorized: authorize}) => {
  const [activeForm, setActiveForm] = useState('authorization-form');

  const processData = (formObj) => {
    // CANNOT find these form parameters:
    // console.log(
    //   formObj.fieldset.form.action,
    //   formObj.fieldset.form.method,
    //   formObj.fieldset.form.encType
    // );
    if (activeForm===authFormSchema.form_attributes.id) {
      authorize(true);
    } else {
      setActiveForm(authFormSchema.form_attributes.id);
    }
  }

  return (
    <div>
      { (activeForm === authFormSchema.form_attributes.id) &&
          <MasterForm schema={authFormSchema} switchTo={setActiveForm} processData={processData} /> }
      { (activeForm === regFormSchema.form_attributes.id) &&
          <MasterForm schema={regFormSchema} switchTo={setActiveForm} processData={processData} />}
      { (activeForm === restoreFormSchema.form_attributes.id) &&
          <MasterForm schema={restoreFormSchema} switchTo={setActiveForm} processData={processData} />
      }
    </div>
  );
};

export default Authorization;
