import React from 'react';
import Authorization from '../authorization/authorization';
import './header.css';
import logo from '../assets/img/logo.svg';
import avatar from '../assets/img/Unsplash-Avatars_caleb-george.png';
import notifications from '../assets/img/icons-src/notifications.svg';
import travelincoin from '../assets/img/icons-src/travelincoin.svg'

const Header = ({authorized, getAuthorized: authorize}) => {

  return (
    <header className={authorized ? 'header' : 'header header_logged-out'}>
      <img src={logo} className={authorized ? 'header__logo' : 'header__logo header__logo_logged-out'}
           alt='Travelingo logo' />
      { authorized ?
        <>
        <div className='header__personal-information'>
          <div className='header__avatar-information'>
            <div className='header__avatar-name'>
              <img src={avatar} className='header__avatar' alt='avatar' />
              <span className='header__name'>Alex Goodman</span>
            </div>
            <span className='header__email'>alexgoodman@gmail.com</span>
          </div>
          <img src={notifications} alt='Notifications' />
        </div>
          <div className='balance'>
            <span className='balance_title'>Balance</span>
            <div className='balance__amounts'>
              <span className='balance__tokens'>
                15 000&nbsp;<img className='balance__travelincoin' src={travelincoin} alt='token symbol' />
              </span>
              <span className='balance__money'>985 €</span>
            </div>
          </div>
        </>
        : <Authorization getAuthorized={authorize} />
      }
    </header>
  );
};

export default Header;
