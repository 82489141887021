import main from '../components/assets/img/icons-src/main.svg';
import statistics from '../components/assets/img/icons-src/statistics.svg';
import vendors from '../components/assets/img/icons-src/vendors.svg';
import MLM from '../components/assets/img/icons-src/mlm.svg';

import support from '../components/assets/img/icons-src/support.svg';
import generals from '../components/assets/img/icons-src/generals.svg';
import exit from '../components/assets/img/icons-src/exit.svg';

export const sidebarButtons = ['main', 'statistics', 'vendors', 'MLM'];
export const footerButtons = ['support', 'generals', 'exit'];

export const icons = {main, statistics, vendors, MLM, support, generals, exit};
