import React, {useState} from 'react';
import './App.css';
import Header from './components/header/header';
import Sidebar from './components/sidebar/sidebar';
import Footer from './components/footer/footer';
import Generals from './forms/generals/generals';
import Support from './forms/support/support';
import {sidebarButtons} from "./references/sections";
import {footerButtons} from "./references/sections";

function App() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [activeSection, setActiveSection] = useState(footerButtons[1]);

  const setActiveSectionOrLogout = (button) => {
    if (button === footerButtons[footerButtons.length - 1]) {
      setIsAuthorized(false);
    } else {
      setActiveSection(prevActiveSection => sidebarButtons.includes(button) ? prevActiveSection : button);
    }
  }

  return (
    <div className={ isAuthorized ? 'App' : 'App App_logged-out' }>
      <Header authorized={isAuthorized} getAuthorized={setIsAuthorized} />
      { isAuthorized && (
        <>
          { (activeSection === footerButtons[0]) && (<Support />) }
          { (activeSection === footerButtons[1]) && (<Generals />) }
          <Sidebar activeButton={activeSection} buttonChange={setActiveSectionOrLogout} />
          <Footer activeButton={activeSection} buttonChange={setActiveSectionOrLogout} />
        </>
      ) }
    </div>
  );
}

export default App;
