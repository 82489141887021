const dummyText = `When a new application is installed, the Android optimize the app data and generates 
a corresponding OAT file. This file is created by the Android OS to accelerate the application loading time. 
The process to generate the OAT file starts with the extraction of classes.dex file present inside 
the .apk file of the application. The classes.dex file is placed in a separate directory and 
Android compiles the Dalvik byte-code with ahead-of-time(AOT, also abbreviated as OAT) compilation 
into native machine code. Android system uses this native OAT file to enhance the user experience 
by loading the application quickly and smoothly`;

export const knowledgeBaseContent = [
  {
    title: 'How it works?',
    text: dummyText
  },
  {
    title: 'Widgets',
    text: dummyText
  },
  {
    title: 'General',
    text: dummyText
  }
];
