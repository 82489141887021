export const regFormSchema = {
  form_attributes: {
    action: '/',
    method: 'post',
    enctype: 'multipart/form-data',
    'accept-charset': 'utf-8',
    id: 'registration-form'
  },
  title: 'Registration',
  fields: [
    {
      type: 'text',
      name: 'name-field',
      label: 'Name'
    },
    {
      type: 'text',
      name: 'email-field',
      label: 'Email'
    },
    {
      type: 'text',
      name: 'phone-field',
      label: 'Phone number'
    }
  ],
  submit: {
    label: 'Registration',
  },
  button: {
    label: 'Login',
    ref_to: 'authorization-form'
  }
};
