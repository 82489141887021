import React from 'react';
import './generals.css';
import generals from '../../components/assets/img/icons-src/generals_black.svg';
import avatar from '../../components/assets/img/Unsplash-Avatars_caleb-george.png';
import plus from '../../components/assets/img/icons-src/add.svg';

const Generals = () => {
  return (
    <main className='main-content'>
      <div className='main-content__header'>
        <img src={generals} className='main-content__icon' alt='Generals icon' />
        <h1 className='main-content__title'>Generals</h1>
      </div>
      <div className='avatar-control'>
        <img src={avatar} className='avatar-control__image' alt='avatar' />
        <span className='avatar-control__button'>
          <img src={plus} className='avatar-control__plus-icon' alt='Plus sign' />
          Add new
        </span>
      </div>
      <form className='profile-form'>
        <fieldset className='profile-form__section'>
          <legend className='profile-form__section-title'>Name</legend>
          <div className='profile-form__field'>
            <label htmlFor='first_name' className='profile-form__field-label'>
              First name <span className='profile-form__field-label_asterisk'>*</span>
            </label>
            <input type='text' id='first_name' name='first_name' defaultValue='Alex' autoComplete='true'
                   className='profile-form__field-input'
                   onChange={() => { }}/>
          </div>
          <div className='profile-form__field'>
            <label htmlFor='surname' className='profile-form__field-label'>
              Surname <span className='profile-form__field-label_asterisk'>*</span>
            </label>
            <input type='text' id='surname' name='surname' defaultValue='Goodman'
                   className='profile-form__field-input' />
          </div>
        </fieldset>
        <fieldset className='profile-form__section'>
          <legend className='profile-form__section-title'>Contacts</legend>
          <div className='profile-form__field'>
            <label htmlFor='phone_number' className='profile-form__field-label'>What is your phone number?</label>
            <input type='text' id='phone_number' name='phone_number'
                   placeholder='8 (&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)' className='profile-form__field-input' />
          </div>
          <div className='profile-form__change-controls'>
            <div className='profile-form__change-block'>
              <span className='profile-form__change-label'>Change password</span>
              <span className='profile-form__change-button'>Change</span>
            </div>
            <div className='profile-form__change-block'>
              <span className='profile-form__change-label'>Change email</span>
              <span className='profile-form__change-button'>Change</span>
            </div>
          </div>
          <input type='submit' value='Save' className='profile-form__submit-button' onClick={(e)=>e.preventDefault()}/>
        </fieldset>
      </form>
    </main>
  );
}

export default Generals;
