export const restoreFormSchema = {
  form_attributes: {
    action: '/',
    method: 'get',
    id: 'restore-password-form'
  },
  title: 'Restore password',
  note: 'Link will be sent to your email. Check it!',
  fields: [
    {
      type: 'text',
      name: 'email-field',
      label: 'Email'
    }
  ],
  submit: {
    label: 'Recovery',
  },
  button: {
    label: 'Back',
    ref_to: 'authorization-form'
  }
};
