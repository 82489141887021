export const authFormSchema = {
  form_attributes: {
    action: '/',
    method: 'get',
    id: 'authorization-form'
  },
  title: 'Authorization',
  fields: [
    {
      type: 'text',
      name: 'login-field',
      label: 'Login'
    },
    {
      type: 'password',
      name: 'password-field',
      label: 'Password'
    }
  ],
  submit: {
    label: 'Log in',
  },
  button: {
    label: 'Registration',
    ref_to: 'registration-form'
  },
  link: {
    label: 'Forgot your password?',
    ref_to: 'restore-password-form'
  }
};
