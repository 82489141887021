import React from 'react';
import './master-form.css';

const MasterForm = ({schema: formSchema, switchTo, processData}) => {

  const validateForm = (e) => {
    e.preventDefault();
    let errors = 0;
    formSchema.fields.forEach(elem => {
      const inputObj = e.target.elements[elem.name];
      const messageContainer = document.getElementById(elem.name + '_error');
      // ============================================= Validation rules should be applied here:
      if (!inputObj.value) {
        // ============================================= Error message should be passed here:
        showError('The data entered is invalid. Please correct.', messageContainer, inputObj);
        errors++;
      } else if (!!messageContainer.textContent) {
        hideError(messageContainer, inputObj);
      }
    });
    if (!errors) {
      // console.log('Errors: ', errors);
      // console.log(e.target.elements);
      processData(e.target.elements);
    }
  }

  const showError = (message, messageContainer, fieldObj) => {
    if (!!message) {
      messageContainer.textContent = message;
      messageContainer.classList.add('master-form__error-message_visible');
      messageContainer.classList.remove('master-form__error-message_hidden');
      fieldObj.classList.add('master-form__input_error');
    }
  }

  const hideError = (messageContainer, fieldObj) => {
    messageContainer.textContent = '';
    messageContainer.classList.remove('master-form__error-message_visible');
    messageContainer.classList.add('master-form__error-message_hidden');
    fieldObj.classList.remove('master-form__input_error');
  }

  return (
    <div className='master-form'>
      <form id={formSchema.form_attributes.id}
            action={formSchema.form_attributes.action}
            method={formSchema.form_attributes.method}
            encType={!!formSchema.form_attributes.enctype ? formSchema.form_attributes.enctype : ''} // Screen the attr?
            onSubmit={validateForm}
            className='master-form__form'>
        <fieldset className='master-form__fieldset'>
          <legend className='master-form__title'>{formSchema.title}</legend>
          { !!formSchema.note && <span className='master-form__note'>{formSchema.note}</span> }
          { formSchema.fields.map((elem, index) => (
            <div key={'auth-'+index}>
              <input type={elem.type} name={elem.name} placeholder={elem.label} className='master-form__input' />
              <span id={elem.name + '_error'}
                    className='master-form__error-message master-form__error-message_hidden' />
            </div>
          )) }
          { !!formSchema.link && (
            <span className='master-form__link'
                  onClick={() => switchTo(formSchema.link.ref_to)}>{formSchema.link.label}</span>
          ) }
          <input type='submit' value={formSchema.submit.label} className='master-form__button' />
        </fieldset>
      </form>
      <div>or</div>
      <button className='master-form__button master-form__button_alternative'
              onClick={() => switchTo(formSchema.button.ref_to)}>{formSchema.button.label}</button>
    </div>
  );
};

export default MasterForm;
