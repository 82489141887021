import React, {useState} from "react";
import {knowledgeBaseContent} from "./knowledge-base-content";
import './knowledge-base.css';
import arrow_up from "../../../components/assets/img/icons-src/arrow_up.svg";
import arrow_down from "../../../components/assets/img/icons-src/arrow_down.svg";

const KnowledgeBase = () => {
  const [activeItem, setActiveItem] = useState(0);

  return (
    <div className='knowledge-base_accordion'>
      { knowledgeBaseContent.map((item, index) => (
          <Panel
            title={item.title}
            isActive={activeItem === index}
            onShow={() => setActiveItem(index)}
            key={'KB-' + index}
          >
            {item.text}
          </Panel>
        )
      ) }
    </div>
  );
};

const Panel = ({
                 title,
                 children,
                 isActive,
                 onShow
}) => {
  const imgSrc = isActive ? arrow_up : arrow_down;

  return (
    <section className='knowledge-base_item' onClick={onShow}>
      <div className='knowledge-base__question-container'>
        <h4 className='knowledge-base__question'>{title}</h4>
        <img src={imgSrc} className='knowledge-base__item-button'  alt='Arrow controls'/>
      </div>
      { isActive && (
        <p className='knowledge-base__answer'>{children}</p>
      ) }
    </section>
  );
};

export default KnowledgeBase;
