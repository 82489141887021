import React from 'react';
import KnowledgeBase from './knowledge-base/knowledge-base';
import './support.css';
import support from '../../components/assets/img/icons-src/support_black.svg';
import download from '../../components/assets/img/icons-src/download.svg';

const Support = () => {
  return (
    <main className='main-content'>
      <div className='main-content__header'>
        <img src={support} className='main-content__icon' alt='Support icon' />
        <h1 className='main-content__title'>Support</h1>
      </div>
      <div className='downloads'>
        <div className='download__item'>
          <h4 className='download__title'>Download promotion information</h4>
          <img src={download} className='download__icon' alt='Download Button' />
        </div>
        <div className='download__item'>
          <h4 className='download__title'>Download instructions</h4>
          <img src={download} className='download__icon' alt='Download Button' />
        </div>
      </div>
      <div className='knowledge-base'>
        <h3 className='knowledge-base__title'>Base of knowledge</h3>
        <KnowledgeBase />
      </div>
      <div className='tg-bot-hint'>
        <h2 className='tg-bot-hint__title'>Do you have any questions?</h2>
        <p className='tg-bot-hint__text'>Write your questions to our Telegram-bot:&nbsp;&nbsp;&nbsp;&nbsp;
          <a href='https://t.me/Travelingo_bot' className='tg-bot-hint__link'
             rel='noreferrer' target='_blank'>https://t.me/Travelingo_bot</a></p>
      </div>
    </main>
  );
}

export default Support;
