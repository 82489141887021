import React from 'react';
import NavButton from "../nav-button/nav-button";
import {sidebarButtons} from '../../references/sections';
import {icons} from '../../references/sections';
import './sidebar.css';

const Sidebar = ({activeButton, buttonChange}) => {
  return (
    <aside className='sidebar'>
      <ul className='sidebar__navigation'>
        { sidebarButtons.map((id, index) => (
          <NavButton
            name={ id }
            icon={icons[id.toString()]}
            isActive={ id === activeButton }
            onActivation={ () => buttonChange(id) }
            key={ 'side-button-' + index }
          />
          )
        ) }
      </ul>
    </aside>
  );
}

export default Sidebar;
