import React from 'react';
import NavButton from '../nav-button/nav-button';
import {footerButtons} from '../../references/sections';
import {icons} from '../../references/sections';
import './footer.css';

const Footer = ({activeButton, buttonChange}) => {
  return (
    <footer className='footer'>
      <ul className='footer__navigation'>
        { footerButtons.map((id, index) => (
          <NavButton
            name={ id }
            icon={icons[id.toString()]}
            isActive={ id === activeButton }
            onActivation={ () => buttonChange(id) }
            key={ 'footer-button-' + index }
          />
          )
        ) }
      </ul>
    </footer>
  );
}

export default Footer;
