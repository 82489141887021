import React from 'react';
import './nav-button.css';

const NavButton = ({ name, icon, isActive, onActivation: makeActive }) => {

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  return(
    <li id={name} className={isActive ? 'button button_active' : 'button'}
        onClick={(e) => makeActive(name)}>
      <img src={icon} className='button__icon' alt={capitalize(name) + ' icon'} />
      <span className='button__label'>{capitalize(name)}</span>
    </li>
  );
};

export default NavButton;
